import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '.././images/logo.png';
import { DotLottiePlayer, Controls } from '@dotlottie/react-player';
import Right from '.././images/right.json';
import wrong from '../images/wrong.json';
import Verify from '../images/verify.json';
import '.././App.css';


const VerifyPage = () => {
  const { tokenid } = useParams();
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [error, setError] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!tokenid) {
          setVerificationStatus('error');
          return;
        }

        const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASEURL}email/${tokenid}`, {});
        if (response.status === 200) {
          setVerificationStatus('verified');
        } else {
          setVerificationStatus('error');
          setError(response.data.message || 'Verification failed');
        }
      } catch (error) {
        console.error('Error verifying token:', error); // Log Axios error for debugging
        setError('An error occurred while verifying the token');
        setVerificationStatus('error');
      }
    };

    verifyToken();
  }, [tokenid]);

  let content;
  switch (verificationStatus) {
    case 'pending':
      content = (
        <div className="success_page">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="logo"  />
            </div>
            <div className='dot' >
              <DotLottiePlayer
                src={Verify} // Example path for error animation
                autoplay
                loop
              >
            
              </DotLottiePlayer>
            </div>
            <h1>Welecome to Tangle </h1>
    
           
          </div>
        </div>
      )
      break;
    case 'verified':
      content = (
       
           <div className="success_page">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="logo"  />
            </div>
            <div className='dot' >
              <DotLottiePlayer
                src={Right} // Example path for error animation
                autoplay
                loop
              >
            
              </DotLottiePlayer>
            </div>
            <h1>Email Verified Successfully</h1>
      <p>Your email has been successfully verified.</p>
           
          </div>
        </div>
       
      );
      break;
    case 'error':
      content = (
        <div className="success_page">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="logo"  />
            </div>
            <div className='dot' >
              <DotLottiePlayer
                src={wrong} // Example path for error animation
                autoplay
                loop
              >
            
              </DotLottiePlayer>
            </div>
            <h1>Email Verification Failed</h1>
            <p>{error || 'An error occurred while verifying the token'}</p>
          </div>
        </div>
      );
      break;
    default:
      content = null;
  }

  return (
    <div className="">
      <div className="">
        {content}
      </div>
    </div>
  );
};

export default VerifyPage;

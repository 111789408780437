
import './App.css';
import {BrowserRouter as Router,Navigate,Routes,Route} from 'react-router-dom';
import Email from './email/email'
function App() {
  return (
    <div className="">
      <Router>
        <Routes>
          <Route path='/verify/:tokenid' element={<Email />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
